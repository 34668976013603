const crypto = require('crypto');
 
const writeReview = function (userId, bvapi) {
  window.onload = function () {
  $.getScript(bvapi)
      .done(function(script, textStatus) {
        const today = new Date().toISOString().split('T')[0];
        let userStr = '';

        if(userId) {
          userStr = 'date=' + today + '&userid=' + userId;
        }
        const sharedKey = 'm5j7d#mE';
        const hmac  = crypto.createHmac('sha256', sharedKey).update(userStr);
        const signature = hmac.digest('hex');
        const hexParameters = userStr.split('').map(c => c.charCodeAt(0).toString(16)).join('');
        const encodedUserString =  signature + hexParameters;
        if(typeof $BV !== 'undefined') {
          $BV.ui('submission_container', {
            userToken : encodedUserString
          });
        };
      })
      .fail(function(jqxhr, settings, exception) {
        console.log("Error Loading BV script.");
      });
    }
  }

module.exports.writeReview = writeReview;
